@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  width: 100%;
  max-width: 300px;
}
.addressBlock {
  display: none;
}
@media (min-width: 960px) {
  .addressBlock {
    height: 22%;
    width: 100%;
  }
}
/* Make the image to responsive */
.image {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  /* background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  /* color: #f1f1f1; */
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  /* color: white; */
  font-size: 20px;
  /* padding: 20px; */
  text-align: center;
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.8);
}

/* When you mouse over the container, fade in the overlay title */
.container:hover .overlay {
  opacity: 1;
}
